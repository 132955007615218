export const checkConnection = async () => {
    try {
        await fetch('https://shorty-api.loidlnetz.de/');
        return true;
    } catch (error) {
        return false;
    }
}

export const getLongUrl = async (shortUrl: string) => {
    try {
        const response = await fetch(`https://shorty-api.loidlnetz.de/get-long-url?shortUrl=${ encodeURIComponent(shortUrl) }`);
        const data = await response.json();
        if (data.data === undefined || data.data === null) return undefined;
        return data.data as string;
    } catch (error) {
        return undefined;
    }
}

export const shortenUrl = async (url: string) => {
    try {
        const response = await fetch(`https://shorty-api.loidlnetz.de/shorten-url?longUrl=${ encodeURIComponent(url) }`, { method: 'POST' });
        const data = await response.json();
        if (data.data === undefined || data.data === null || data.data.length === 0) return undefined;
        return data.data as string;
    } catch (error) {
        return undefined;
    }
}
