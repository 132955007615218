import React from 'react'
import './App.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import CreateUrl from "./CreateUrl";
import RedirectLoader from "./RedirectLoader";

export default function App() {
    return <Router>
        <Routes>
            <Route path="" element={ <CreateUrl/> }/>
            <Route path=":shortUrl" element={ <RedirectLoader/> }/>
            {/*<Route path="*" element={ <Navigate to={ "" }/> }/>*/ }
        </Routes>
    </Router>
}
