import { Component } from "react"
import "./CreateUrl.css"
import moon from "./moon.png"
import spaceship from "./spaceship.png"
import { checkConnection, shortenUrl } from "./connection";

interface Props {

}

interface State {
    interval?: any,
    connected: boolean,
    shortUrl?: string
}

export default class CreateUrl extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            connected: false
        }
    }

    componentDidMount() {
        for (let i = 0; i < 20; i++) {
            this.createStar()
        }

        this.setState({
            interval: setInterval(() => {
                this.createShootingStar()
                checkConnection().then((connected) => this.setState({ connected }))
            }, 2000)
        })

        checkConnection().then((connected) => this.setState({ connected }))
    }

    componentWillUnmount() {
        clearInterval(this.state.interval)
    }

    generateStar = (shootingStar?: boolean) => {
        const star = document.createElement("img")
        star.src = "./star.png"
        star.className = "star no-select" + (shootingStar ? " shooting-star" : "")
        star.style.position = "absolute"
        star.style.left = Math.random() * 100 + "%"
        star.style.top = Math.random() * 100 + "%"
        star.style.transform = "scale(" + (Math.random() * 0.8 + 0.5) + ") rotate(" + (Math.random() * 360) + "deg)"
        document.querySelector(".create-url")?.appendChild(star)
        return star
    }

    createStar = () => {
        let star = this.generateStar()
        star.style.animationDelay = Math.random() * 5 + "s"
        star.style.animationDuration = Math.random() * 5 + 5 + "s"
        star.style.animationIterationCount = "infinite"
        star.style.animationName = "twinkle"
        document.querySelector(".create-url")?.appendChild(star)
    }

    createShootingStar = () => {
        let star = this.generateStar(true)
        star.animate([
            { transform: star.style.transform + " translate(0, 0)", opacity: 0 },
            { opacity: 1 },
            {
                transform: "translate(" + (Math.random() * 100 - 50) + "vw, " + (Math.random() * 100 - 50) + "vh) scale(" + (Math.random() * 0.8 + 0.5) + ") rotate(" + (Math.random() * 360) + "deg)",
                opacity: 0
            }
        ], {
            duration: 5000,
            easing: "linear",
            fill: "forwards"
        }).onfinish = () => star.remove()
    }

    landSpaceship = () => {
        let wrapper = document.querySelector(".spaceship-wrapper")
        let spaceship = document.querySelector(".spaceship")
        let moon = document.querySelector(".moon")

        ;(wrapper as HTMLElement).style.animationPlayState = "paused"
        ;(moon as HTMLElement).style.animationPlayState = "paused"
        wrapper?.animate([
            { transform: "rotate(0deg)", height: "250px" }
        ], {
            duration: 1000,
            easing: "ease-out",
            fill: "forwards"
        })
        spaceship?.animate([
            { top: "50%", left: "-10px", transform: "translate(-50%, -50%) rotate(45deg)" }
        ], {
            duration: 1000,
            easing: "ease-in-out",
            fill: "forwards"
        })
        moon?.animate([
            { height: "250px", width: "250px", transform: "translateX(250px)" }
        ], {
            duration: 1000,
            easing: "ease-in-out",
            fill: "forwards"
        })
        setTimeout(() => {
            moon?.animate([
                { transform: "translateX(0)" }
            ], {
                duration: 1000,
                easing: "ease-in-out",
                fill: "forwards"
            })
            spaceship?.animate([
                {
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%) rotate(45deg)"
                }
            ], {
                duration: 1000,
                easing: "ease-in-out",
                fill: "forwards"
            })
        }, 1000)
    }

    copyToClipboard = () => {
        let url = this.state.shortUrl
        if (!url) return
        navigator.clipboard.writeText(url).then(r => document.querySelector(".url-input")?.animate([
            { outlineColor: "white" },
            { outlineColor: "green" },
            { outlineColor: "white" }
        ], {
            duration: 500,
            easing: "linear",
            fill: "forwards"
        }))
    }

    render() {
        return <>
            <main
                className={ "create-url" + (this.state.connected ? "" : " no-connection") + (this.state.shortUrl ? " complete" : "") }>
                <h1 className={ "title neon no-select" }>SHORTEN YOUR URL</h1>
                <h2 className={ "subtitle neon no-select" }>WHERE SHOULD ONE LAND?</h2>
                <div className={ "spaceship-wrapper no-select" }>
                    <img src={ moon } alt={ "" } className={ "moon no-select" } height={ 500 } width={ 500 }/>
                    <img src={ spaceship } alt={ "" } className={ "spaceship no-select" } height={ 100 } width={ 100 }/>
                </div>
                <div className={ "url-input" }
                     onClick={ (e) => {
                         this.copyToClipboard()
                         e.currentTarget.querySelector("input")?.focus()
                     } }>
                    <label htmlFor={ "url" }>
                        <i className={ "fas fa-map-pin" }/>
                    </label>
                    <input type={ "text" } name={ "url" } id={ "url" } placeholder={ "Target Destination" }
                           className={ this.state.shortUrl ? "no-select" : "" }
                           onFocus={ (e) => {
                               if (!this.state.connected) {
                                   e.preventDefault()
                                   e.target.blur()
                                   return
                               }
                               e.target.parentElement?.classList.add("active")
                           } }
                           onBlur={ (e) => {
                               e.target.parentElement?.classList.remove("active")
                           } }
                           onKeyUp={ (e) => {
                               if (e.key === "Enter") {
                                   let url = encodeURI(e.currentTarget.value)
                                   if (!url.startsWith("http://") && !url.startsWith("https://")) url = "http://" + url
                                   shortenUrl(url).then((shortUrl) => {
                                       if (shortUrl) {
                                           shortUrl = "https://shorty.loidlnetz.de/" + shortUrl

                                           document.querySelector(".url-input")?.classList.remove("active")

                                           setTimeout(() => {
                                               // @ts-ignore
                                               document.getElementById("url")!.value = shortUrl
                                               document.querySelector(".url-input")?.classList.add("active")
                                           }, 1000)

                                           this.setState({ shortUrl })
                                           this.landSpaceship()
                                       } else document.querySelector(".url-input")?.animate([
                                           { outlineColor: "white" },
                                           { outlineColor: "red" },
                                           { outlineColor: "white" },
                                           { outlineColor: "red" },
                                           { outlineColor: "white" },
                                           { outlineColor: "red" },
                                           { outlineColor: "white" }
                                       ], {
                                           duration: 1000,
                                           easing: "linear",
                                           fill: "forwards"
                                       })
                                   })
                               }
                           } }
                           onClick={ () => this.copyToClipboard() }
                           disabled={ this.state.shortUrl !== undefined }/>
                </div>
            </main>
            <footer className={ "create-url-footer" }>
                <span>Shorty &copy; loidlNetz { new Date().getFullYear() }</span>
            </footer>
        </>
    }
}
