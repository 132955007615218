import { useParams } from "react-router-dom";
import { getLongUrl } from "./connection";

export default function RedirectLoader() {
    const { shortUrl } = useParams();

    getLongUrl(shortUrl ?? "").then((longUrl) => {
        if (longUrl) window.location.href = longUrl
        else window.location.href = "/"
    })

    return <></>
}
